<template>
  <b-sidebar
    id="sidebar-profile-edit"
    ref="sidebarProfileEdit"
    v-model="isShowSidebar"
    aria-labelledby="sidebar-action-header"
    right
    backdrop
    shadow
    no-header
    width="80vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter">Edit Personal Information</b-col>
      </b-row>
      <div class="py-3">
        <div class="pb-3">
          <b-row>
            <b-col>Prefer Date:</b-col>
            <b-col>{{ form.created_time | moment($formatDateNew) }}</b-col>
          </b-row>
          <b-row>
            <b-col>Spending:</b-col>
            <b-col>{{ form.grand_total | numeral("0,0.00") }} Baht</b-col>
          </b-row>
          <b-row class="align-items-center">
            <b-col>Status Consent:</b-col>
            <b-col>
              <b-form-select
                value-field="id"
                text-field="name"
                v-model="form.is_consent"
                :options="isConsentList"
              ></b-form-select>
            </b-col>
          </b-row>
        </div>
        <div>
          <UploadFile
            textFloat="Profile Picture"
            placeholder="Please Select a File To Upload."
            format="image"
            name="thumbnail"
            :fileName="form.picture"
            :accept="acceptType"
            text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
            v-on:onFileChange="onImageChange"
          />
          <div
            class="panel-bg-file-img mr-1 mb-1"
            v-bind:style="{
              backgroundImage: 'url(' + form.picture + ')',
            }"
          >
            <img
              src="@/assets/icons/loading.svg"
              class="loading"
              alt="loading"
              v-if="isLoadingImage"
            />
            <font-awesome-icon
              icon="times-circle"
              class="text-secondary f-right pointer"
              v-if="form.picture"
              @click.prevent="onDeleteImage"
            />
          </div>
        </div>

        <b-row class="mt-3">
          <b-col sm="12" md="6">
            <InputSelect
              title="Name Title"
              name="Name Title"
              :options="prefixOption"
              v-model="form.prefix"
            >
              <!-- isRequired -->
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >Please Select
                </b-form-select-option>
              </template>
            </InputSelect>
          </b-col>
          <div class="break-normal"></div>
          <b-col sm="12" md="6">
            <InputText
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="first_name_th"
              v-model="form.first_name_th"
              isRequired
              :v="v.form.first_name_th"
              :isValidate="v.form.first_name_th.$error"
            />
          </b-col>
          <b-col sm="12" md="6">
            <InputText
              textFloat="Surname"
              placeholder="Surname"
              type="text"
              name="last_name_th"
              v-model="form.last_name_th"
              isRequired
              :v="v.form.last_name_th"
              :isValidate="v.form.last_name_th.$error"
            />
          </b-col>

          <b-col cols="6" class="mb-3">
            <label class="main-label mb-2">Birthday </label>
            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.birthday"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                ref="birthdayDate"
              >
              </datetime>
              <div
                :class="'icon-primary text-right'"
                @click="$refs.birthdayDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <InputSelect
              title="Membership Level"
              name="level"
              v-model="form.member_level_id"
              noPleaseSelect
              v-bind:options="memberLevelList"
              valueField="id"
              textField="name"
              @onDataChange="selectMemberLevel"
            />
          </b-col>
          <b-col cols="6" class="mb-3">
            <label class="main-label mb-2">Start Date </label>
            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.tier_start_date"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                value-zone="Asia/Bangkok"
                :disabled="true"
                ref="tier_start_date"
              >
              </datetime>
              <div :class="'icon-primary text-right'">
                <!-- @click="$refs.tier_start_date.isOpen = true" -->
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="' color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
          <b-col cols="6" class="mb-3">
            <label class="main-label mb-2">End Date</label>
            <div class="input-container">
              <datetime
                type="date"
                :input-style="styleDatetime"
                v-model="form.expire_date"
                placeholder="DD/MM/YYYY"
                format="dd/MM/yyyy"
                :min-datetime="form.tier_start_date"
                value-zone="Asia/Bangkok"
                ref="tier_end_date"
              >
              </datetime>
              <div
                :class="'icon-primary text-right'"
                @click="$refs.tier_end_date.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  :class="'pointer color-primary'"
                  color="#B41BB4"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <MultipleRadioButton
              :label="'Gender'"
              :id="'gender'"
              :name="'gender'"
              :options="genderChoice"
              :textField="'name'"
              :valueField="'name'"
              :value="form.gender"
              @onDataChange="handleChange"
            />
            <!-- :disabled="true" -->
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Tel."
              placeholder="Tel."
              type="number"
              name="Tel."
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 10);'"
              v-model="form.telephone"
              isRequired
              :v="v.form.telephone"
              :isValidate="v.form.telephone.$error"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Email"
              placeholder="Email"
              type="text"
              name="email"
              v-model="v.form.email.$model"
              :v="v.form.email"
              :isValidate="v.form.email.$error"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <InputText
              textFloat="Identification Number"
              placeholder="Identification Number"
              type="number"
              name="Identification Number"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 13);'"
              v-model="v.form.id_card.$model"
              :v="v.form.id_card"
              :isValidate="v.form.id_card.$error"
            />
          </b-col>
        </b-row>
        <b-row class="point-box mt-2">
          <b-col>
            <div>
              <span class="title-point">Total Point:</span>
              <button class="icon-to-point" @click.prevent="openModalPoint">
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
            <div class="title-total-point">
              {{ form.center_point | numeral("0,0") }}
            </div>
          </b-col>
        </b-row>
        <b-row
          class="point-box mt-2"
          v-if="$store.state.theme.themeLogo.result.is_center_point == 0"
        >
          <b-col>
            <div>
              <span class="title-point">Branch Point:</span>

              <button
                class="icon-to-point"
                @click.prevent="openModalBranchPoint"
              >
                <font-awesome-icon icon="chevron-right" />
              </button>
            </div>
            <div class="title-total-point">
              {{ form.total_branch_point | numeral("0,0") }}
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
            :disabled="isLoadingUpdateProfile"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import MultipleRadioButton from "@/components/inputs/MultipleRadioButton";
export default {
  components: { UploadFile, InputText, InputSelect, MultipleRadioButton },
  props: {
    form: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    FieldList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      image: "",
      acceptType: "image/jpeg, image/png",
      isLoadingImage: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      isLoadingImage: false,
      isDisable: false,
      prefixOption: [],
      genderChoice: [],
    };
  },
  mounted() {},
  methods: {
    setFields() {
      var check;
      // console.log(this.FieldList);
      if (this.FieldList.length > 0) {
        const gender = this.FieldList.find(
          (el) => el.field_profile_type_id == 21
        );
        const prefix = this.FieldList.find(
          (el) => el.field_profile_type_id == 2
        );

        gender.field_choices.map((el) =>
          el.name == "ไม่ระบุ" ? (el.name = "N/A") : el.name
        );

        this.genderChoice = gender.field_choices.filter(
          (el) => el.is_display == 1
        );

        const isPrefixDisplay = prefix.field_choices.filter(
          (el) => el.is_display == 1
        );
        this.prefixOption = isPrefixDisplay.map((el) => el.name);
        check = isPrefixDisplay.find((el) => el.name == this.form.prefix);
      }

      if (!check) {
        this.form.prefix = "";
      }
    },
    handlePrefix() {
      this.$nextTick(() => {
        let male = ["เด็กชาย", "นาย"];
        let Female = ["เด็กหญิง", "นาง", "นางสาว"];

        if (male.includes(this.form.prefix)) {
          this.form.gender = "Male";
        } else if (Female.includes(this.form.prefix)) {
          this.form.gender = "Female";
        } else this.form.gender = "N/A";
      });
    },
    show() {
      this.setFields();

      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.picture = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage() {
      this.form.picture = "";
    },

    selectMemberLevel(value) {
      this.form.member_level_id = value;
    },
    submitAction() {
      this.v.form.$touch();

      if (this.v.form.$error) {
        return;
      }

      this.$emit("updateProfile", this.form);
      this.hide();
    },
    handleChange(val) {
      this.form.gender = val;
    },
    openModalPoint() {
      this.$emit("openModalPoint", 1);
    },
    openModalBranchPoint() {
      this.$emit("openModalPoint", 2);
    },
    optionFilter() {
      if (this.prefixOption) {
        let check = this.prefixOption.find((el) => el.name == this.form.prefix);
        if (!check) {
          this.form.prefix = "";
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
.panel-bg-file-img {
  width: 50%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  margin-bottom: 15px;
  position: relative;
}

.icon-primary {
  flex: 1 1 auto;
  cursor: text;
}
.title-label {
  color: #16274a;
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.consent-allow {
  color: #06c306;
}
.consent-cancel {
  color: red;
}
.f-right {
  float: right;
}
.point-box {
  margin: 0px;
  padding: 10px 5px;
  background-color: var(--secondary-color);
}
.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  margin-left: 5px;
  font-size: 10px;
  border-radius: 3px;
  padding: 3px 9px;
}
.title-point {
  color: gray;
}
.title-total-point {
  font-weight: 600;
  font-size: 17px;
}
</style>
