<template>
  <div>
    <b-row class="py-2 py-lg-3">
      <b-col sm="12" md="6" lg="4">
        <div class="card rounded-large shadow">
          <div class="card-body">
            <div
              :class="
                form.is_consent === 1
                  ? 'icon-profile-consent mx-auto'
                  : 'icon-profile mx-auto'
              "
              :style="'background-image: url(' + form.picture + ');'"
            >
              <span v-if="!form.picture">
                {{
                  form.first_name_th
                    ? form.first_name_th.substring(1, 0).toUpperCase()
                    : ""
                }}
              </span>
            </div>
            <div class="position-check">
              <!-- <font-awesome-icon icon="check-circle" class="check-circle" /> -->
              <font-awesome-icon
                class="check-circle pointer"
                icon="pencil-alt"
                @click.prevent="sidebarEditProfile"
              />
            </div>
            <font-awesome-icon
              class="trash-icon"
              icon="trash-alt"
              @click.prevent="openModalDeleteUser"
            />
            <div class="customer-name">
              {{ form.first_name_th }} {{ form.last_name_th }} ({{
                form.member_id
              }})
            </div>
            <hr />
            <b-row class="customer-detail">
              <b-col sm="6">
                <div>Birthday</div>
                <div>
                  {{
                    $moment(form.birthday).year() == "0001" ||
                    $moment(form.birthday).year() == "1752" ||
                    !form.birthday
                      ? "-"
                      : form.birthday | moment($formatDateNew)
                  }}
                </div>
              </b-col>
              <b-col sm="6">
                <div>Gender</div>
                <div>{{ form.gender || "-" }}</div>
              </b-col>
              <b-col sm="6">
                <div>Register Date</div>
                <div>{{ form.created_time | moment($formatDateNew) }}</div>
              </b-col>
              <b-col sm="6">
                <div>Telephone</div>
                <div>{{ form.telephone }}</div>
              </b-col>
              <b-col sm="6">
                <div>Email</div>
                <div>{{ form.email || "-" }}</div>
              </b-col>
              <b-col sm="6">
                <div>Address</div>
                <div>
                  {{ fullAddr(form).trim() == "" ? "-" : fullAddr(form) }}
                </div>
              </b-col>
              <b-col sm="6">
                <div>Connected Channel</div>
                <div class="mt-2 d-flex" style="gap: 5px">
                  <img
                    class="market-image"
                    src="http://api-hourglass-admin-dev.dosetech.co/Upload/220323100329372.png"
                    alt="dcrm shop"
                  />
                  <div
                    v-for="connect of form.connect_channel"
                    :key="connect.id"
                  >
                    <img
                      class="market-image"
                      :src="connect.marketplaceUrl"
                      alt="marketplace image"
                    />
                  </div>
                </div>
              </b-col>
              <b-col cols="12">
                <hr />

                <template
                  v-if="
                    form.user_custom_field.filter(
                      (el) => el.user_answer.length > 0
                    ).length > 0
                  "
                >
                  <div class="content-between mb-2">
                    <b>Additional Questions</b>
                    <font-awesome-icon
                      @click="$refs.sidebarEditQues.show()"
                      icon="pencil-alt"
                      title="Edit"
                      class="main-color cursor-pointer"
                    />
                  </div>
                  <div
                    v-for="custom_field of form.user_custom_field"
                    :key="custom_field.id"
                  >
                    <template
                      v-if="
                        custom_field.user_answer &&
                        custom_field.user_answer.length > 0
                      "
                      class="mb-3"
                    >
                      <div
                        :class="[
                          custom_field.field_type_id == 10 ||
                          custom_field.field_type_id == 11
                            ? 'text-bold text-black '
                            : 'f-14',
                          { 'f-16': custom_field.field_type_id == 11 },
                          { 'f-18': custom_field.field_type_id == 10 },
                        ]"
                      >
                        {{ custom_field.name }}
                      </div>
                      <template v-if="custom_field.user_answer.length > 0">
                        <div
                          v-for="answer of custom_field.user_answer"
                          :key="answer.id"
                        >
                          <span v-if="custom_field.field_type_id == 8">
                            <b-img
                              :src="answer.answer"
                              class="w-100 h-100"
                            ></b-img>
                          </span>
                          <span v-else-if="custom_field.field_type_id == 5">
                            {{
                              $moment(answer.answer).format(
                                "DD MMM YYYY (HH:mm)"
                              )
                            }}
                          </span>

                          <span v-else-if="custom_field.field_type_id == 7">
                            {{ $moment(answer.answer).format("DD MMM YYYY") }}
                          </span>
                          <span v-else>{{ answer.answer }} </span>
                        </div>
                      </template></template
                    >
                  </div>
                </template>
              </b-col>
              <b-col cols="12">
                <div class="content-between mb-2">
                  <b>Additional Tag</b>
                  <font-awesome-icon
                    @click="$refs.sidebarEditTag.show()"
                    icon="pencil-alt"
                    title="Edit"
                    class="main-color cursor-pointer"
                  />
                </div>
                <div class="card">
                  <div class="p-1" v-if="form.user_tag.length > 0">
                    <b-row no-gutters
                      ><b-col
                        v-for="(tags, i) of form.user_tag"
                        :key="tags.id + '-' + i"
                        cols="3"
                        class="p-1"
                      >
                        <b-badge class="w-100 f-12" v-if="i < 11">{{
                          tags.name
                        }}</b-badge>
                        <div
                          v-else-if="i == 11"
                          class="text-center cursor-pointer"
                          @click="$refs.sidebarEditTag.show()"
                        >
                          + {{ form.user_tag.length + 1 - 12 }} Tags
                        </div>
                      </b-col></b-row
                    >
                  </div>
                  <div class="p-1 text-center" v-else>
                    <b-button @click="$refs.sidebarEditTag.show()"
                      >Add new Tag</b-button
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
            <!-- <pre>{{ form }}</pre> -->
          </div>
        </div>
      </b-col>
      <b-col sm="12" md="6" lg="8">
        <div class="card rounded-large shadow">
          <div class="card-body">
            <h4 class="mb-2">Member Information</h4>
            <b-row class="customer-more-detail">
              <b-col>
                <div>Member Tier</div>
                <div>{{ form.member_level_name }}</div>
              </b-col>
              <b-col>
                <div>Point</div>
                <div>
                  <!-- <pre>{{ form }}</pre> -->
                  {{ form.point_total | numeral("0,0") }}
                </div>
              </b-col>
              <b-col>
                <div>Expire Date</div>

                <div v-if="form.expire_date">
                  {{ form.expire_date | moment("DD/MM/YYYY") }}
                </div>
                <div v-else>-</div>
              </b-col>
              <b-col>
                <div>Consent Status</div>
                <div
                  :class="form.is_consent == 1 ? 'text-green' : 'text-error'"
                >
                  {{ form.is_consent == 1 ? "Yes" : "No" }}
                </div>
              </b-col>
            </b-row>
            <hr />
            <h4 class="mb-2">Purchase Information</h4>
            <b-row class="customer-more-detail">
              <b-col md="4" sm="6" lg="2">
                <div>Total Spending</div>
                <div>{{ form.grand_total | numeral("0,0") }} Baht</div>
              </b-col>
              <div class="break-normal"></div>
              <b-col md="4" sm="6" lg="2">
                <div>Last Purchase Date</div>
                <div>
                  {{ form.last_purchase_date || "-" | moment("DD/MM/YYYY") }}
                </div>
              </b-col>
              <b-col md="4" sm="6" lg="2">
                <div>Customer Lifecycle</div>
                <div>{{ form.customer_lifecycle || "-" }}</div>
              </b-col>
              <div class="break-normal"></div>
              <b-col md="4" sm="6" lg="2">
                <div>First Purchase Branch</div>
                <div>{{ form.first_purchase_branch || "-" }}</div>
              </b-col>
              <b-col md="4" sm="6" lg="2">
                <div>First Purchase Channel</div>
                <div>{{ form.first_purchase_channel || "-" }}</div>
              </b-col>
              <b-col md="4" sm="6" lg="2">
                <div>Last Purchase Branch</div>
                <div>
                  <!-- <pre>{{ form }}</pre> -->
                  {{ form.last_purchase_branch || "-" }}
                </div>
              </b-col>
              <b-col md="4" sm="6" lg="2">
                <div>Last Purchase Channel</div>
                <div>{{ form.last_purchase_channel || "-" }}</div>
              </b-col>

              <div class="break-normal"></div>
              <b-col md="4" sm="6" lg="2">
                <div>Recency</div>
                <div class="mt-3 range-wrap">
                  <div class="range-value" id="rangeV"></div>
                  <div
                    class="range-avg"
                    id="range-recen-avg"
                    :avg="form.recently.average"
                  ></div>
                  <!-- {{ form.recently }} -->
                  <input
                    id="range"
                    type="range"
                    :value="form.recently.value"
                    :max="form.recently.max"
                    :min="form.recently.min"
                    :data-avg="form.recently.average"
                    step="0.1"
                    disabled
                  />
                </div>
                <div class="content-between">
                  <small>MIN </small> <small>MAX</small>
                </div>
              </b-col>
              <b-col md="4" sm="6" lg="2">
                <div>Frequency</div>
                <div class="mt-3 range-wrap">
                  <div class="range-value" id="rangeV-freq"></div>
                  <div
                    class="range-avg"
                    id="range-freq-avg"
                    :avg="form.frequency.average"
                  ></div>
                  <input
                    id="range-freq"
                    type="range"
                    :value="form.frequency.value"
                    :max="form.frequency.max"
                    :min="form.frequency.min"
                    :data-avg="form.frequency.average"
                    step="0.1"
                    disabled
                  />
                </div>
                <div class="content-between">
                  <small>MIN </small> <small>MAX</small>
                </div>
              </b-col>
              <b-col md="4" sm="6" lg="2">
                <div>Monetary</div>
                <div class="mt-3 range-wrap">
                  <div class="range-value" id="rangeV-mon"></div>
                  <div
                    class="range-avg"
                    id="range-mon-avg"
                    :avg="form.monetary.average"
                  ></div>
                  <input
                    id="range-mon"
                    type="range"
                    :value="form.monetary.value"
                    :max="form.monetary.max"
                    :min="form.monetary.value == 0 ? 0 : form.monetary.min"
                    :data-avg="form.monetary.average"
                    step="0.1"
                    disabled
                  />
                </div>

                <div class="content-between">
                  <small>MIN </small> <small>MAX</small>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <SidebarEditProfile
      ref="sidebarProfileEdit"
      :form="form"
      :memberLevelList="memberLevelList"
      :FieldList="FieldList"
      @updateProfile="updateProfile"
      :v="v"
      @openModalPoint="openModalPoint"
      :isLoadingUpdateProfile="isLoadingUpdateProfile"
      :isConsentList="isConsentList"
    />
    <SidebarEditPersonalProfile
      ref="sidebarPersonalEdit"
      :list="customerQuestionList"
      @submitEditPersonal="submitEditPersonal"
      :isLoadingUpdatePersonal="isLoadingUpdatePersonal"
    />
    <SidebarEditTag
      ref="sidebarEditTag"
      :form="form.user_tag"
      @fetch="$emit('fetch')"
    />
    <SidebarEditQuestion
      ref="sidebarEditQues"
      :form="form.user_custom_field"
      :branchCustomField="branchCustomField"
      @fetch="$emit('fetch')"
    />
    <SidebarEditAddress
      ref="sidebarAddressEdit"
      :form="form"
      @updateAddress="updateAddress"
    />
    <ModalDeleteUser ref="modalDeleteUser" @deleteUser="deleteUser" />
  </div>
</template>

<script>
import SidebarEditProfile from "@/components/report/customer/detail/profile/SidebarEditProfile";
import SidebarEditTag from "@/components/report/customer/detail/profile/SidebarEditTag";
import SidebarEditQuestion from "@/components/report/customer/detail/profile/SidebarEditQuestion";
import SidebarEditAddress from "@/components/report/customer/detail/profile/SidebarEditAddress";
import ModalDeleteUser from "./modal/DeleteUser";
import SidebarEditPersonalProfile from "@/components/report/customer/detail/profile/SidebarEditPersonalProfile";

export default {
  components: {
    SidebarEditProfile,
    SidebarEditAddress,
    SidebarEditPersonalProfile,
    SidebarEditTag,
    ModalDeleteUser,
    SidebarEditQuestion,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    memberLevelList: {
      required: true,
      type: Array,
    },
    FieldList: {
      required: true,
      type: Array,
    },
    v: {
      required: true,
      type: Object,
    },
    customerQuestionList: {
      required: true,
      type: Array,
    },
    isLoadingPersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdatePersonal: {
      required: true,
      type: Boolean,
    },
    isLoadingUpdateProfile: {
      required: true,
      type: Boolean,
    },
    isConsentList: {
      required: true,
      type: Array,
    },
    branchCustomField: {
      required: false,
    },
  },
  mounted() {
    this.createTooltipRange("range", "rangeV", "range-recen-avg");
    this.createTooltipRange("range-freq", "rangeV-freq", "range-freq-avg");
    this.createTooltipRange("range-mon", "rangeV-mon", "range-mon-avg");
  },
  methods: {
    selectBestMulp(value) {
      switch (value) {
        case value < 30:
          return 0.25;

        case value < 40:
          return 0.17;

        default:
          return 0.15;
      }
    },
    createTooltipRange(input, label, avg) {
      const range = document.getElementById(input);

      const rangeV = document.getElementById(label);
      const rangeAvg = document.getElementById(avg);
      const avgValue = range.dataset.avg;

      const newValue = Number(
        ((range.value - range.min) * 100) / (range.max - range.min)
      );
      let avgPosition = Number(
        ((avgValue - range.min) * 100) / (range.max - range.min)
      );

      let mulp = this.selectBestMulp(newValue);
      let newPosition = 8 - newValue * mulp;
      if (newValue == 100) newPosition = -5;
      if (newValue == 0) newPosition = 5;

      rangeV.innerHTML = `<span>${range.value}</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
      rangeAvg.style.left = `${avgPosition}%`;
    },
    fullAddr(form) {
      let addr = `${form.home_address || ""} ${form.alley || ""} ${
        form.road || ""
      } ${form.town || ""} ${form.subdistrict || ""} ${form.district || ""} ${
        form.province || ""
      } ${form.zip_code || ""}`;
      return addr.trim() == "" ? "-" : addr;
    },
    sidebarEditProfile() {
      this.$refs.sidebarProfileEdit.show();
    },
    openModalDeleteUser() {
      this.$refs.modalDeleteUser.show();
    },
    async deleteUser(val) {
      this.$refs.modalDeleteUser.hide();

      let message = "";
      if (val == 0)
        message =
          "Do you confirm to delete account and move data to anonymous user";
      else
        message = "Do you confirm to delete account and all data permanently";
      this.confirmAlert({ message: message }).then(async (isCheck) => {
        if (isCheck.value) {
          this.$EventBus.$emit("showLoading");
          var response = null;
          if (val == 0) {
            response = await this.$Axios.delete(
              `${process.env.VUE_APP_API}/User/DeleteUserChangeAnnonymous/${this.form.user_guid}`
            );
          } else {
            response = await this.$Axios.delete(
              `${process.env.VUE_APP_API}/User/DeleteUserAllData/${this.form.user_guid}`
            );
          }
          this.$EventBus.$emit("hideLoading");
          if (response.data.result) {
            this.successAlert().then(() =>
              this.$router.replace("/report/customer")
            );
          } else {
            this.errorAlert(response.data.message);
          }
        }
      });
    },
    sidebarEditAddress() {
      this.$refs.sidebarAddressEdit.show();
    },
    updateAddress(value) {
      this.$emit("updateAddress", value);
    },
    updateProfile(value) {
      this.$emit("updateProfile", value);
    },
    openModalPoint(val) {
      this.$emit("openModalPoint", val);
    },
    sidebarEditProsonal() {
      this.$refs.sidebarPersonalEdit.show();
    },
    submitEditPersonal(val) {
      this.$emit("updatePersonal", val);
    },
    chkBirthday(birthday) {
      let birthDate = birthday ? birthday.search("00:00:00") : -1;

      // let birthDate = birthday.search("00:00:00");
      if (birthDate === -1) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-large {
  border-radius: 0.75rem;
  border-color: unset;
}
::v-deep .card-body {
  h4 {
    font-weight: 600;
    color: rgb(96, 96, 96);
  }

  color: black;
}

.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 50%;
  bottom: 0px;
}
.name {
  font-weight: 600;
}
.bg-icon-point {
  background-color: #ffbe33;
  border-radius: 50px;
  color: #ffe8b8;
}
.icon-bd {
  color: var(--primary-color);
}
.bd-social {
  background-color: var(--secondary-color);
  border-radius: 50px;
  padding: 5px 9px;
  border: none;
}
.img-icon {
  height: auto;
  width: auto;
  max-width: 30px;
  max-height: 30px;
}
.lh-25 {
  line-height: 23px;
}
.collapse.width {
  transition: right 0.3s linear, max-width 0.3s linear;
  max-height: 100% auto;
  max-width: 100%;
  min-width: 50px;
  right: 0;
  position: relative;
  display: block;
}
.icon-profile {
  text-align: center;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  padding: 10px 0;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  position: relative;
}
.icon-profile-consent {
  // background-image: url(var(--background));
  background-size: contain;
  padding: 5px 0;
  text-align: center;
  border: 3px solid #2eb85c;
  border-radius: 50px;
  width: 70px;
  height: 70px;
  font-size: 35px;
  font-weight: 600;
  background-color: var(--secondary-color);
  color: var(--primary-color);
}
.icon-level {
  height: auto;
  width: auto;
  max-width: 15px;
  max-height: 15px;
}
.text-underline {
  text-decoration: underline;
}
.title-color {
  color: var(--primary-color);
}
::v-deep .btn-secondary {
  background-color: transparent;
  border: none;
}
.check-circle {
  color: #000000;
  font-size: 1.25rem;
  padding: 3px;
  background-color: #acffc8;
  border-radius: 50px;
  border: 2px solid #2eb85c;
}
.position-check {
  top: 15px;
  left: 53%;
  position: absolute;
}
.question {
  color: gray;
}
.answer {
  color: #000;
}
.customer-name {
  text-align: center;
  font-size: 18px;
  margin: 10px 0px;
}
.customer-detail,
.customer-more-detail {
  row-gap: 0.75rem;
  div {
    div:first-child {
      white-space: nowrap;
      color: rgb(159, 159, 159);
      // font-size: 12px;
    }
  }
}
.customer-more-detail {
  column-gap: 20px;
  row-gap: 10px;
}

body {
  min-height: 100vh;
  padding: 0 10vh;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  animate: 0.2s;
  background: var(--primary-color);
  border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  position: relative;
  z-index: 2;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  background: #fff;
  -webkit-appearance: none;
  margin-top: -3px;
}

.range-wrap {
  width: 100%;
  position: relative;
}
.range-value {
  position: absolute;
  top: -50%;
}
::v-deep .range-value span {
  width: 30px;
  height: 18px;
  line-height: 18px;
  text-align: center;
  background: var(--primary-color);
  color: #fff;
  font-size: 11px;
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 6px;
  top: -5px;
}
::v-deep .range-value span:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid var(--primary-color);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  margin-top: -1px;
}
::v-deep .range-avg {
  height: 10px;
  width: 10px;
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
  background: #cbc1c1;
  z-index: 1;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%);
}
.text-green {
  color: #2eb85c;
}
.text-error {
  color: red;
}
.range-avg:before {
  color: rgba(42, 0, 193, 0.182);
  content: "Average (" attr(avg) ") ";
  position: absolute;
  width: auto;
  font-size: 10px;
  color: #bfbfbf;
  height: 20px;
  white-space: nowrap;
  top: 100%;
  left: 50%;
  transform: translate(-30%, 100%);
  margin-left: -5px;
  margin-top: -1px;
}
.range-avg:after {
  content: "";
  height: 17px;
  border-left: thin dashed;
  position: absolute;
  overflow: hidden;
  top: 100%;
  border-color: rgb(61 61 61 / 25%);
  left: 50%;
  transform: translate(-50%, 15%);
}
.market-image {
  width: 30px;
  height: auto;
}
.trash-icon {
  position: absolute;
  top: 20px;
  right: 30px;
  cursor: pointer;
  color: var(--primary-color);
  font-size: 18px;
}
.badge {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}
</style>
