<template>
  <div>
    <b-row class="no-gutters px-3 px-sm-0">
      <!-- <b-col md="6" class="d-flex align-items-center text-left">
        <h4 class="mr-sm-4 header-tablepage">
          {{ title }}
        </h4>
      </b-col> -->
      <b-col md="12" class="text-right">
        <b-input-group>
          <b-form-input

            class="search-bar"
            @keyup.enter="handleSearch"
            :placeholder="placeholder"
            v-debounce:200ms="handleSearch"
            v-model="filter.search"
            :disabled="tab === 3 ? true : false"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack
              font-scale="2"
              type="submit"
              @click="handleSearch"
              :disabled="tab === 3 ? true : false"
            >
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
          <b-button
            @click.prevent="sidebarFilter"
            class="btn-filter ml-2"
            v-if="btnFilter"
            :disabled="tab === 3 ? true : false"
          >
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="filter" class="pointer" />
              Filter
            </span>
          </b-button>
          <button
            type="button"
            class="btn button btn-mobile mx-2"
            @click.prevent="exportExcel"
            v-if="btnExport"
          >
            <font-awesome-icon icon="filter" class="text-black d-sm-none" />
            <span class="d-none d-sm-block">
              <font-awesome-icon icon="file-export" class="pointer" />
              Export
            </span>
          </button>
        </b-input-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
    placeholder: {
      required: true,
      type: String,
    },
    btnFilter: {
      required: false,
      type: Boolean,
    },
    btnExport: {
      required: false,
      type: Boolean,
    },
    tab: {
      required: false,
      type: Number,
    },
  },
  methods: {
    handleSearch() {
      this.$emit("filterCoupon", this.filter);
    },
    exportExcel() {
      this.$emit("confirmExport");
    },
    sidebarFilter() {
      this.$emit("sidebar");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
  font-weight: 100;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
}
</style>
