<template>
  <b-sidebar
    id="sidebar-address-edit"
    ref="sidebarAddressEdit"
    v-model="isShowSidebar"
    right
    backdrop
    shadow
    no-header
    width="60vw"
  >
    <div>
      <b-row class="header-filter" no-gutters>
        <b-col class="text-filter px-3">Edit Additional Tag</b-col>
      </b-row>
      <div class="p-3">
        <InputText
          textFloat="Additional Tag"
          placeholder="Add a tag (Ex. tag1 , tag2)"
          type="text"
          v-model="tags"
          @onKeypress="addTag"
          v-debounce:500ms="handleTag"
        />
        <div class="tag-panel">
          <b-row no-gutters style="column-gap: 0.5rem; row-gap: 0.5rem">
            <b-col
              cols="3"
              style="flex: 1"
              v-for="(tag, i) of tagsList"
              :key="tag.name + '-' + i"
              class="p-1"
            >
              <b-badge class="w-100 f-12 content-between"
                ><div>{{ tag.name }}</div>
                <font-awesome-icon
                  @click="removeTags(i)"
                  icon="times"
                  title="Edit" /></b-badge
            ></b-col>
          </b-row>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Save
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  components: {},
  props: {
    form: {
      required: true,
    },
  },
  data() {
    return {
      isShowSidebar: false,
      tags: "",
      tagsList: [...this.form],
      deleteList: [],
    };
  },
  methods: {
    handleMaxlength(e) {
      if (e.target.value.length == 5) {
        return e.preventDefault();
      }
    },
    show() {
      this.tagsList = [...this.form];
      this.isShowSidebar = true;
    },
    hide() {
      this.deleteList = [];
      this.tags = "";

      this.isShowSidebar = false;
    },
    handleTag() {
      if (this.tags.trim() != "") {
        for (const tags of this.tags.split(",")) {
          if (this.tagsList.find((el) => el == tags)) {
          } else {
            this.tagsList.push({ id: 0, name: tags });
          }
        }
        // this.tagsList.push(...this.tags.split(","));
      }
      this.tags = "";
    },
    addTag(e) {
      if (e.keyCode == 13 && e.target.value != "") {
        for (const tags of this.tags.split(",")) {
          if (this.tagsList.find((el) => el == tags)) {
          } else {
            this.tagsList.push({ id: 0, name: tags });
          }
        }
        // this.tagsList.push(...this.tags.split(","));
        this.tags = "";
      }
    },
    removeTags(index) {
      let deleteArr = this.tagsList.splice(index, 1);

      this.deleteList = [...this.deleteList, ...deleteArr];
    },
    async submitAction() {
      try {
        let payload = {
          id: 0,
          user_guid: this.$route.params.id,
          tag: this.tagsList.filter((el) => el.id == 0),
        };

        if (this.deleteList.filter((el) => el.id != 0).length > 0) {
          let delPayload = {
            id: 0,
            user_guid: this.$route.params.id,
            tag: this.deleteList.filter((el) => el.id != 0),
          };
          const res = await this.$Axios.post(
            `${process.env.VUE_APP_API}/customer/DeleteUserTag`,
            delPayload
          );
        }
        if (payload.tag.length > 0) {
          const res = await this.$Axios.post(
            `${process.env.VUE_APP_API}/customer/InsertUserTag`,
            payload
          );
        }
        this.$emit("fetch");
        this.successAlert();
        this.hide();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .b-sidebar {
  width: 400px;
}
.header-filter {
  background-color: var(--secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
  font-size: 17px;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.badge {
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
